import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DateField from '@/shared/fields/date-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import IntegerField from '@/shared/fields/integer-field';


function label(name) {
  return i18n(`entities.importer.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.importer.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  itemId: new StringField('itemId', label('itemId'), {}),
  itemType: new EnumeratorField('itemType', label('itemType'), [
    { value: 'product', label: enumeratorLabel('itemType', 'product') },
    { value: 'sparePart', label: enumeratorLabel('itemType', 'sparePart') },
    { value: 'accessory', label: enumeratorLabel('itemType', 'accessory') },
  ],{}),
  warrantyStatus: new EnumeratorField('warrantyStatus', label('warrantyStatus'), [
    { value: 'inactive', label: enumeratorLabel('warrantyStatus', 'inactive') },
    { value: 'active', label: enumeratorLabel('warrantyStatus', 'active') },
    { value: 'expired', label: enumeratorLabel('warrantyStatus', 'expired') },
  ],{}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'inStock', label: enumeratorLabel('status', 'inStock') },
    { value: 'inFactory', label: enumeratorLabel('status', 'inFactory') },
    { value: 'exported', label: enumeratorLabel('status', 'exported') },
  ],{}),
  qrCode: new StringField('qrCode', label('qrCode'), {}),
  serialNumber: new StringField('serialNumber', label('serialNumber'), {}),
  activationDate: new DateField('activationDate',label('activationDate')),
  expirationDate: new DateField('expirationDate',label('expirationDate')),
  
  sellerId: new StringField('sellerId', label('sellerId'), {}),
  exportedBy: new StringField('exportedBy', label('exportedBy'), {}),
  exportedAt: new DateTimeField('exportedAt',label('exportedAt')),
  
  activatedBy: new StringField('activatedBy', label('activatedBy'), {}),
  // userId: new StringField('userId', label('userId'), {}),
  // iterationNumber: new IntegerField('iterationNumber',label('iterationNumber')),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt',label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ExporterModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
