import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import DateField from '@/shared/fields/date-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import IntegerField from '@/shared/fields/integer-field';


function label(name) {
  return i18n(`entities.activation.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.activation.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  items: new StringArrayField('items', label('items'), {}),
  activator: new JsonField('activator', label('activator')),
  activatedBy: new StringField('activatedBy', label('activatedBy')),
  limitExceeded: new BooleanField('limitExceeded', label('limitExceeded'), {
    yesLabel: enumeratorLabel('activationStatus', 'exceeded'),
    noLabel: enumeratorLabel('activationStatus', 'normal'),
  }),
  userType: new EnumeratorField('userType', label('userType'), [
    { value: 'admin', label: enumeratorLabel('userType', 'admin') },
    { value: 'seller', label: enumeratorLabel('userType', 'seller') },
    { value: 'client', label: enumeratorLabel('userType', 'client') },
  ],{}),
  disabledAsStatus: new BooleanField('disabled',
    label('userStatus'),
    {
      noLabel: i18n('user.enabled'),
      yesLabel: i18n('user.disabled'),
    },
  ),
  disabled: new BooleanField('disabled',
    label('disabled'),
    {
      noLabel: i18n('user.enabled'),
      yesLabel: i18n('user.disabled'),
    },
  ),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt',label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ActivationModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
