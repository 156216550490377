import { render, staticRenderFns } from "./activation-view-table.vue?vue&type=template&id=0f30e1fe&scoped=true&"
import script from "./activation-view-table.vue?vue&type=script&lang=js&"
export * from "./activation-view-table.vue?vue&type=script&lang=js&"
import style0 from "./activation-view-table.vue?vue&type=style&index=0&lang=css&"
import style1 from "./activation-view-table.vue?vue&type=style&index=1&id=0f30e1fe&lang=scss&scoped=true&"
import style2 from "./activation-view-table.vue?vue&type=style&index=2&id=0f30e1fe&lang=scss&scoped=true&"
import style3 from "./activation-view-table.vue?vue&type=style&index=3&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f30e1fe",
  null
  
)

export default component.exports
import {QTable,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QInput,QIcon,QTr,QTh,QTd,QBtn,QTooltip})
