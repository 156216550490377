//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ActivationModel } from '@/modules/activation/activation-model';
import ActivationViewTable from '@/modules/activation/components/activation-view-table';
// import { ActivationPermissions } from '@/modules/activation/activation-permissions';
import  i18n  from '@/vueI18n';
import moment from 'moment';
import firebase from 'firebase/app'
import 'firebase/firestore';

const { fields } = ActivationModel;

export default {
  name: 'app-activation-view-page',
  props: ['id'],

  components: {
    [ActivationViewTable.name]: ActivationViewTable,
  },

  async created() {
    await this.doFind(this.id);
    if (this.record.unread) {
      this.markAsRead()
    }
  },
  data() {
    return {
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      filter:'',
      selection:[],
      columns: [
        {
          name: 'id',
          field: 'id',
          label: '#',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'name',
          field: 'itemDetails',
          label: 'entities.importer.fields.itemName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 'entities.importer.fields.serialNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'itemType',
          field: 'itemType',
          label: 'entities.importer.fields.itemType',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'status',
          field: 'status',
          label: 'entities.importer.fields.status',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'exportedAt',
          field: 'exportedAt',
          label: 'entities.importer.fields.exportedAt',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'warrantyStatus',
          field: 'warrantyStatus',
          label: 'entities.importer.fields.warrantyStatus',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'activationDate',
          field: 'activationDate',
          label: 'entities.importer.fields.activationDate',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'expirationDate',
          field: 'expirationDate',
          label: 'entities.importer.fields.expirationDate',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'clientName',
          field: 'activatedBy',
          label: 'entities.importer.fields.clientName',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'clientPhone',
          field: 'activatedBy',
          label: 'entities.importer.fields.clientPhone',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label:'entities.importer.fields.createdAt',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'time',
          field: 'time',
          label: 'common.time',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'qrCode',
          field: 'qrCode',
          label: 'entities.importer.fields.qrCode',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'action',
        //   field: 'action',
        //   label: i18n('common.actions'),
        //   align: 'center',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      record: 'activation/form/record',
      loading: 'activation/form/findLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    fields() {
      return fields;
    },
    language(){
      return i18n.locale
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    statusColor() {
      return (state) => {
        let color 
        switch (state) {
          case 'inactive':
            color = '#bc8283'
            break;
          case 'active':
            color = '#2FA84F'
            break;
          case 'expired': 
            color = '#FD3018'
            break
          default:
            color = '#003A5D'
            break;
        }
        return color
      }
    },
    userTypeColor() {
      return (user) => {
        let color 
        const type = user.userType
        switch (type) {
          case 'admin':
            color = '#00B2E3'
            break;
          case 'client':
            color = '#2FA84F'
            break;
          case 'seller': 
            color = '#FD3018'
            break
          default:
            color = '#003A5D'
            break;
        }
        return color
      }
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.activation.label') +
            ' | ' +
            this.i18n('entities.activation.view.title') 
    },
  },
  methods: {
    ...mapActions({
      doFind: 'activation/form/doFind',
      // doDisableAllSelected: 'iam/list/doDisableAllSelectedById',
      // doEnableAllSelected: 'iam/list/doEnableAllSelectedById',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(`entities.activation.enumerators.userType.${val[0].value}`) : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(ActivationModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = ActivationModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },
    presenterUser(row, fieldName, key) {
      const user = ActivationModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      if (key == 'userType') {
        return this.presenterEnum(user, key)
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },

    presenterClient(row, fieldName, key) {
      const val = ActivationModel.presenter(row, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    async markAsRead() {
      firebase.firestore().collection('activation').doc(this.id).update({ unread: false })
    }
  },
};
